.box-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #538cc6;
    border-radius: 5px;
    height: 50px;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
}
