.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #538cc6;
}

.login-box {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 50px;
    border-radius: 10px;
    text-align: center;
}

.form-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-title{
    font-size: xx-large;
    margin-bottom: 10px;
}

.alert{
    margin-top: 20px;
}