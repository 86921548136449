@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #538cc6;
}

.login-box {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 50px;
    border-radius: 10px;
    text-align: center;
}

.form-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-title{
    font-size: xx-large;
    margin-bottom: 10px;
}

.alert{
    margin-top: 20px;
}
.box-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #538cc6;
    border-radius: 5px;
    height: 50px;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
}


body {
    background-color: #F5F9F6 !important;
}

h1 {
    font-size: 1em;
}

.nav {
    height: 30px; 
    justify-content: space-between;
}

.menu-logo-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 30px;
}

.menu-logo{
    width: 150px;
}

.menu{
    width: 60px;
    height: 100%;
    background-color: #538cc6;
    color: white;
    /*left: 260px;*/
    position: fixed;
    margin-left: -100px;
    margin-top: 20px;
    left: 100px;
    overflow-y: auto;
    z-index: 1000;
}

.menu-title{
    font-weight: 900;
    font-size: 14px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.menu ul {
    list-style-type: none;
}

.menu li{
    font-size: 14px;
    padding-bottom: 10px;
    text-decoration: none;
}

.menu-icon{
    display: flex;
    align-items: center;
    font-size: 15px;
    height: 20px;
}

.menu-option-text{
    /*height: 20px;*/
    margin-top: 30px;
    -webkit-transform:translate(0px);
            transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 0;
    transition: transfom opacity 
}

.menu-option-text-hover{
    margin-top: 20px;
    -webkit-transform:translate(0px);
            transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 1;
    transition: transfom opacity 
}

.menu-option-wrapper{
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
}

.menu-option-label {
    margin-left: 10px;
    display: none;
}

.home-wrapper{
    padding-left: 70px;
    left: 0px;
}

.home-main {
    margin-left: 70px;
}

.menu-user-label {
    visibility: visible;
}

.component-container {
    width: 100%;
}

.brand-title {
    font-weight: bolder;
    
}

.page-title {
    font-size: 25px;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.page-title2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.page-subtitle {
    font-size: x-large;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.menu-option-icon {
    font-size: 30px;
    transition: all .2s ease-in-out;
    opacity: 0.5;
}

.menu-option-icon-hover {
    font-size: 30px;
    opacity: 1;
    transition: all 500ms;
}

.info-container{
    margin-top: 30px;
}

.info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    margin-bottom: 50px;
    padding-left: 5px;
    padding-right: 5px;
}

.info2{
    display: flex;
    flex-direction: column;
    
    margin: 5px;
    margin-bottom: 50px;
    padding-left: 5px;
    padding-right: 5px;
}

.info-state-container {
    display: flex;
    align-items: center;
}

.info-state-icon {
    font-size: x-large;
}

.info-state-text {
    margin-right: 10px;
}

.info-title {
    font-weight: bold;
    margin-right: 20px;
}

.info-text {
    display: flex;
    flex-direction: column;
}

.top {
    position: fixed;
}

.home-spinner {
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-container {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.refresh-icon {
    font-size: x-large;
}

.main-table {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    font-size: small;
    text-align: center;
}

.main-table th {
    color: white;
    font-weight: bold;
    background-color: #538cc6;
    padding-left: 5px;
    padding-right: 5px;
    border-left: solid 1px whitesmoke;
}

.box-container {
    background-color: white;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    height: 100%;
    font-size: 12px;
    box-shadow: 5px 11px 15px 3px rgba(114,114,114,0.7)
}

.box-row {
}

.box-switch {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 90%;
}

.box-title {
    font-weight: bolder;
    font-size: 12px;
}

.component-container {
    border-radius: 5px;
    background-color: white;
    /*padding: 10px;*/
    margin-bottom: 10px;
}

.boxes {
    display: none;
}

a:link {
    text-decoration: none;
    color: black;
}
  
a:visited {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: none;
    color: black;
}

a:active {
    text-decoration: none;
    color: black;
}

.menu-button {
    display: none;
}

@media (max-width:320px) {
    .component-container {
        /*width: 350px;*/
    }
}

@media (max-width:375px) {
    .component-container {
        /*width: 350px;*/
    }
}

@media (max-width:768px) {

    .brand-title {
        display: flex;
        align-items: center;
        height: 20px;
        overflow: hidden;
        font-size: 14px;
    }

    .tooltip {
        display: none;
    }
    
    .menu-button {
        /*height: 20px;*/
        overflow: hidden;
        border: 1px solid;
        border-color: rgba(255,255,255,.1);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
        width: 40px;
        font-size: 20px;
        padding: 3px;
    }

    .nav {
        height: 40px; 
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .menu ul {
        list-style-type: none;
    }

    .menu {
        left: 0;
        transition: all 0.4s ease 0s;
    }

    .home-wrapper {
        position: relative;
        padding-left: 0;
        transition: all 0.4s ease 0s;
        left: 0px;
    }

    .home-wrapper-active{
        /*padding-left: 260px;
        left: 0px;*/
        transition: all 0.4s ease 0s;
    }

    .menu-active{
        width: 200px;
        height: 100%;
        background-color: #538cc6;
        color: white;
        /*left: 260px;*/
        position: fixed;
        margin-left: -100px;
        margin-top: 20px;
        left: 100px;
        overflow-y: auto;
        z-index: 1000;
        transition: all 0.4s ease 0s;
    }

    .menu-option-wrapper{
        justify-content: left;
        margin-left: 20px;
    }

    .menu-option-label {
        margin-left: 10px;
        display: inherit;
    }

    .home-row{
        margin: auto;
        margin-bottom: 10px;
    }

    .home-main {
        margin-left: 0;
        padding-top: 80px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .page-container {
        padding-top: 80px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .menu-user-label {
        display: none;
    }

    .main-table {
        display: none;
    }

    .boxes {
        display: inline;
        display: initial;
    }

    .box-col {
        display: flex;
        align-items: center;
    }

    .pc-page {
        display: none;
    }
}

.back-button {
    font-size: 20px;
    margin-bottom: 10px;
}





.pos-servers-info-container{
    margin-top: 30px;
}

.pos-servers-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.pos-servers-state-container {
    display: flex;
    align-items: center;
}

.pos-servers-state-icon {
    font-size: x-large;
}

.pos-servers-state-text {
    margin-right: 10px;
}

.pos-servers-info-title {
    font-weight: bold;
}
.button {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: .25rem;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: .375rem .75rem;
    font-size: 1rem;
    border: 1px solid transparent;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.profile-text-box {
    width: 100%;
    height: 30px;
    padding: 4px;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.profile-field {
    /*display: flex;
    justify-content: space-between;
    align-items: center;*/
    margin-top: 20px;
}

.profile-field-name {
    font-weight: bold;
    font-size: 13px;
}

.profile-field-hint {
    color: #003f81;
    font-size: 11px;
    width: 200px;
}
