.pos-servers-info-container{
    margin-top: 30px;
}

.pos-servers-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.pos-servers-state-container {
    display: flex;
    align-items: center;
}

.pos-servers-state-icon {
    font-size: x-large;
}

.pos-servers-state-text {
    margin-right: 10px;
}

.pos-servers-info-title {
    font-weight: bold;
}